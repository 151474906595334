<template>
    <div class="online-checkout-popup">
        <div class="card card-bordered">
        <div class="card-header ribbon mt-5" style="padding: 0 0 0 1.5rem">
        <div class="row flex-nowrap w-100">
            <div class="col-6 mb-5">
              <!-- <div class="card-title">
                <h6 class="card-subtitle text-muted">
                  4. Invoice Information:
                </h6>
              </div> -->
            </div>
            <div class="col-3 mt-5 text-end">
              <label
                for="exampleFormControlInput1"
                class="form-label  text-warning"
                >Policy Sales Agent</label>
            </div>
            <div class="col-3">
              <div class="mb-5 d-grid">
                <el-input
                  v-if="data.invoice.policy_sales_agent"
                  :model-value="data.invoice.policy_sales_agent.name"
                  disabled
                />
                
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-3 mb-5">
              <label for="exampleFormControlInput1" class=" form-label"
                >Insurance Plan
              </label>
                
                <input
                type="text"
                placeholder="Sales Price"
                class="form-control m-input"
                rows="3"
                disabled
                :value="data.invoice.policy.policy_name"
              />
            </div>
            <div class="col-3 mb-5">
              <label for="agency_repair" class="form-label "
                >Is agency repair?</label
              >
              <input
                type="text"
                placeholder="Sales Price"
                class="form-control m-input"
                rows="3"
                disabled
                :value="data.invoice.is_agency_repair === 1 ? 'Yes' : 'No'"
              />
            </div>

            <div class="col-3 mb-5" v-if="data.invoice.amend_type == '0'">
              <label for="exampleFormControlInput1" class="form-label "
                >Policy Price
                <span class="fw-light text-gray-700 fs-8">(Original Price) (AED)</span>
              </label>
              <vc-amount-v2 
                v-model="myData.invoice.original_price"
                disabled
              />
            </div>
            <div class="col-3 mb-5">
              <label for="exampleFormControlInput1" class=" form-label"
                >Sales Price
                <span class="fw-light text-gray-700 fs-8">(AED)</span>
              </label>
              <vc-amount-v2 
                v-model="myData.invoice.sale_price"
                disabled
              />
              
            </div>

            <div class="col-3 mb-5">
              <label for="exampleFormControlInput1" class=" form-label"
                >Promotion code
              </label>
              <input
                v-if="myData && myData.invoice && myData.invoice.promo_code"
                type="text"
                placeholder="Not Applied"
                v-model="myData.invoice.promo_code.promotion_code"
                class="form-control m-input"
                rows="3"
                disabled
              />
              <input
                v-else
                type="text"
                placeholder="Not Applied"
                class="form-control m-input"
                rows="3"
                disabled
              />
            </div>

            <div class="col-3 mb-5" v-if="data.invoice.amend_type == '0'">
              <label for="exampleFormControlInput1" class=" form-label"
                >Discount Given
              </label>
              <div class="row">
                  <div
                    class="form-check form-check-custom form-check-solid col-3"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      :value="1"
                      :checked="data.invoice.is_discount_given == 1"
                      name="is_discount_given"
                      disabled
                    />
                    <label class="form-check-label entity-type-fs" for="flexRadioChecked"
                      >Yes</label
                    >
                  </div>
                  <div
                    class="form-check form-check-custom form-check-solid col-3"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      :value="0"
                      :checked="data.invoice.is_discount_given === 0"
                      name="is_discount_given"
                      disabled
                    />
                    <label class="form-check-label entity-type-fs" for="flexRadioChecked"
                      >No</label
                    >
                  </div>
                </div>
            </div>
            <div class="col-3 mb-5" v-if="data.invoice.amend_type == '0'">
                <label for="exampleFormControlInput1" class=" form-label"
                  >Discount Amount
                  <span class="fw-light text-gray-700 fs-8">(AED)</span>
                </label>
                <vc-amount-v2 
                  v-model="myData.invoice.discount_amount"
                  disabled
                />
            </div>

            <div class="col-3 mb-5">
              <label for="exampleFormControlInput1" class=" form-label"
                >Payment type
              </label>
              <input
                type="text"
                placeholder="Sales Price"
                :value="data.invoice.payment_type_text"
                class="form-control m-input"
                rows="3"
                disabled
              />
              
            </div>
            
            <div class="col-3 mb-5">
              <label for="exampleFormControlInput1" class=" form-label">
                <span> Order description </span>
              </label>
              <input
                type="text"
                placeholder="Order Description"
                :value="data.invoice.order_description"
                class="form-control m-input"
                rows="3"
                disabled
              />
            </div>
            <div class="col-3 mb-5" v-if="[2,5,3].includes(data.invoice.payment_type)">
              <label for="exampleFormControlInput1" class=" form-label">
                <span>Reference No.</span>
              </label>
              <input
                type="text"
                placeholder="Reference No."
                :value="data.invoice.reference_no"
                class="form-control m-input"
                rows="3"
                maxLength="50"
                ref="referenceNoRef"
                disabled
              />
              
            </div>
            <div
              class="col-3 mb-5"
              v-if="data.invoice.payment_type == 1"
            >
              <label for="exampleFormControlInput1" class="form-label"
                >Link Expiry date</label
              >
              <div>
                <input
                  type="text"
                  placeholder="Reference No."
                  :value="data.invoice.expiry_date_time"
                  class="form-control m-input"
                  rows="3"
                  maxLength="50"
                  ref="referenceNoRef"
                  disabled
                />
              </div>
            </div>
            <div
              class="col-3 mb-5"
              v-if="data.invoice.payment_type == 4"
            >
              <label for="invoiceFileUpload" class=" form-label"
                >Cancelled Policy Quote Ref No.</label
              >
              <input
                type="text"
                placeholder="Reference No."
                :value="data.invoice.car_lead_quote.quote_ref_no"
                class="form-control m-input"
                rows="3"
                maxLength="50"
                ref="referenceNoRef"
                disabled
              />
            </div>
            <div
              class="col-3 mb-5"
              v-if="data.invoice.payment_type == 1"
            >
              <label
                for="exampleFormControlInput1"
                class="form-label"
                >Language display for Online Payment</label
              >
              <input
                type="text"
                placeholder="Reference No."
                :value="data.invoice.language"
                class="form-control m-input"
                rows="3"
                maxLength="50"
                ref="referenceNoRef"
                disabled
              />
            </div>
            <div
              class="col-8 mb-5"
              v-if="data.invoice.payment_type == 1 && data.invoice.payment_link"
            >
              <label
                for="exampleFormControlInput1"
                class="form-label"
                >Payment Link</label
              >
              <p class="mt-4"> {{ data.invoice.payment_link }} </p>
            </div>
            <div class="col-3 mb-5" v-if="[2,5,3].includes(data.invoice.payment_type)">
              <label for="exampleFormControlInput1" class=" form-label">
                <span>Payment Date</span>
              </label>
              <div>
                <input
                type="text"
                placeholder="Reference No."
                :value="data.invoice.payment_date"
                class="form-control m-input"
                rows="3"
                maxLength="50"
                ref="referenceNoRef"
                disabled
              />
              </div>
            </div>
            <div class="col-3 mt-8" v-if="data.invoice.invoice_doc && [2,5,3, 4].includes(data.invoice.payment_type)">
              <label for="exampleFormControlInput1" class=" form-label">
                <span>File/Receipt : <a :href="data.invoice.invoice_doc.file_path" target="_blank"> {{ data.invoice.invoice_doc.filename }} </a> </span>
              </label>
            </div>
        </div>
          </div>
      </div>
      </div>
</template>

<script setup>

import { defineProps, toRef } from 'vue'

const props = defineProps({
    data: {
        type: Object,
        required: true
    }
})

const myData = toRef(props, 'data')

</script>
