export const amendCustomerDetailsProps = ['name', 'email']
export const amendDriverDetailsProps = ['new_phone_number', 'new_name', 'new_email', 'personal_use', 'current_policy_active', 'third_party_liability', 'car_value', 'dob', 'policy_start_date', 'nationality', 'first_driving_license_country', 'driving_experience', 'uae_driving_experience', 'claimed_insurance', 'claims', 'no_claim_certificate', 'gender', 'driver_name']
export const amendInvoiceDetailsProps = ['policy_end_date', 'amount', 'payment_type', 'insurance_payment', 'order_description', 'reference_no', 'payment_date', 'invoice_doc', 'expiry_date_time', 'language']
export const invoiceStatus = [
    { id: 1, name: "Pending" },
    { id: 2, name: "Approve" },
    { id: 3, name: "Decline" }
]
export const invoiceOnlineStatus = [
    { id: 1, name: "Pending" },
    { id: 3, name: "Decline" }
]
export const invoiceDescripancyStatus = [
    { id: 2, name: "For Discrepancy approval " },
    { id: 4, name: "Approve with Discrepancy" },
    { id: 5, name: "Update Discrepancy" }
]