import { computed, ref, watchEffect } from "vue"
import { useRoute } from "vue-router"
import { useStore } from "vuex"
import { useUpdateCustomerData } from '@/store/composable/Customer'
import { useUser, useRoleBasedConditions } from '@/store/composable/User'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setBreadCrumbs, setFromBreadCrumbs } from '@/store/composable/BreadCrumbs'
import moment from "moment";

const { role_id } = useUser()
const d_start_date = moment().subtract(3, 'months').format('YYYY-MM-DD')
const d_end_date = moment().format('YYYY-MM-DD')
export async function useListing(filters, route_name) {
    const roles = useRoleBasedConditions()

    if(route_name && !roles[conditions(route_name)] && role_id) window.location.href = '/#/404'
    
        const params = {
            linkType: route_name,
            ...filters
        }
        let breadcrumb = setBreadCrumbs(params.linkType)
        let fromBreadcrumb = setFromBreadCrumbs(params.linkType)
        setCurrentPageBreadcrumbs(breadcrumb, fromBreadcrumb)
        const response = await useUpdateCustomerData(params)
        return response
}

export function resetLeadsTaskP() {
    dateRanges.value = []
    search.value = ''
    leadsTaskP.value = {
        per_page: 50,
        task_due_dates: "all",
        customer_status_id: -1,
        agent: [1, 14, 15].includes(role_id) ? 'all' : -1,
        lead_status_id: -1,
        keyword: "",
        start_date: "",
        end_date: "",
        is_hot_lead: false,
        is_hot_renewal_lead: false,
        page: 1
    }
}


export function setPolicyP(route_name, query) {
    dateRanges.value = []
    search.value = ''
    let start_date = ''
    let end_date = ''
    let task_due_dates = 'all'
    let keyword = ''
    if(['policy-completed', 'policy-cancelled', 'policy-refund-closed'].includes(route_name)) {
        if(query && query.sf) {
            search.value = query.sf
            keyword = query.sf
            task_due_dates = 'all'
        } else {
            start_date = d_start_date
            end_date = d_end_date
            task_due_dates = 'custom'
            dateRanges.value = [d_start_date, d_end_date]
        }
        
    }
    const params = {
        agent: [1, 9].includes(role_id) ? 'all' : 'mine',
        end_date,
        index: 1,
        keyword,
        payment_type: "",
        per_page: 50,
        policy_sales_agent: "",
        start_date,
        status: setStatus(route_name),
        task_due_dates,
        type: setType(route_name),
        underwriter: "",
        page: 1
    }
    if(['policy-cancellation-request', 'policy-cancelled', 'policy-refund-closed'].includes(route_name)) {
        policyACP.value = {
            ...params,
            is_cancellation_selected: 1
        } 
    } else if(route_name === 'policy-cancellation-approval') {
        delete params.task_due_dates
        policyACP.value = {
            ...params,
            is_cancellation_selected: 1,
            cancellation_status: "",
            refund_status: ""
        } 
    } else if(route_name === 'policy-refund-approval') {
        delete params.task_due_dates
        policyACP.value = {
            ...params,
            is_cancellation_selected: 0,
            cancellation_status: "",
            refund_status: 1
        } 
    } else {
        policyACP.value = {
            ...params
        }
    }
    
}

export function setInvoiceP(type, prev = null, route_name = null) {
    const params = {
        agent: [1, 9].includes(role_id) ? 'all' : 'mine',
        end_date: prev ? prev.end_date : "",
        index: 1,
        page: 1,
        is_amend: -1,
        is_discrepancy: null,
        is_manual_order: null,
        keyword: prev ? prev.keyword : "",
        payment_type: prev ? prev.payment_type : -1,
        per_page: "50",
        start_date: prev ? prev.start_date : "",
        status: "2",
        tabTask: 'all',
        route: route_name ? route_name : ''
    }

    switch(type) {
        case 'all':
            invoiceP.value = { ...params }
            break
        case 'new':
            invoiceP.value = { 
                ...params,
                is_amend: "0",
                is_discrepancy: 0,
                tabTask: 'new'
            }
            break
        case 'amend_invoice':
            invoiceP.value = {
                ...params,
                is_amend: "1",
                tabTask: 'amend_invoice'
            }
            break
        case 'manual_order':
            invoiceP.value = {
                ...params,
                is_manual_order: 1,
                tabTask: 'manual_order'
            }
            break
        case 'discrepancy':
            invoiceP.value = {
                ...params,
                is_discrepancy: 2,
                is_manual_order: [0, 1],
                tabTask: 'discrepancy'
            }
            break
        case 'cod_direct_count': 
        case 'cod_direct_intransact': 
            invoiceP.value = {
                ...params,
                is_discrepancy: 0,
                is_manual_order: 0,
                payment_type: [2, 3, 4],
                status: 1,
                tabTask: 'cod_direct_count'
            }
            break
        case 'manual_order_count': 
            invoiceP.value = {
                ...params,
                is_discrepancy: 0,
                is_manual_order: 1,
                payment_type: [2, 3],
                status: 1,
                tabTask: 'manual_order_count'
            }
            break
        case 'discrepancy_count':
            invoiceP.value = {
                ...params,
                is_discrepancy: 1,
                is_manual_order: [0, 1],
                payment_type: [1, 2, 3],
                status: -1,
                tabTask: 'discrepancy_count'
            }
            break
        case 'online_count': 
            invoiceP.value = {
                ...params,
                is_discrepancy: 0,
                is_manual_order: [0, 1],
                payment_type: [1],
                status: 1,
                tabTask: 'online_count'
            }
            break
        case 'failed_payments_count':
            invoiceP.value = {
                ...params,
                is_discrepancy: 0,
                is_manual_order: [0, 1],
                payment_type: [1, 2, 3, 4],
                status: 6,
                tabTask: 'failed_payments_count'
            }
            break
        default: 
            delete params.is_manual_order

            invoiceP.value = {
                ...params,
                agent: "mine",
                is_discrepancy: 0,
                payment_type: [1, 2, 3, 4],
                status: 3,
                tabTask: 'declined'
            }
            break
    }
}

export function setLeadsTaskP(current_role_id) {
    leadsTaskP.value = {
        per_page: 50,
        task_due_dates: "all",
        customer_status_id: -1,
        agent: [1, 14, 15].includes(current_role_id) ? 'all' : -1,
        lead_status_id: -1,
        keyword: "",
        start_date: "",
        end_date: "",
        is_hot_lead: false,
        is_hot_renewal_lead: false,
        page: 1
    }
}

export function setUserP() {
    userSearch.value = ''
    userP.value = {
        agent: 'all',
        end_date: '',
        page: 1,
        keyword: '',
        per_page: 50,
        role_id: 0,
        start_date: '',
        status: ''
    }
}

export const leadsTaskP = ref({
    per_page: 50,
    task_due_dates: "all",
    customer_status_id: -1,
    agent: [1, 14, 15].includes(role_id) ? 'all' : -1,
    lead_status_id: -1,
    keyword: "",
    start_date: "",
    end_date: "",
    is_hot_lead: false,
    is_hot_renewal_lead: false,
    page: 1
})

export const userP = ref({
    agent: 'all',
    end_date: '',
    page: 1,
    keyword: '',
    per_page: 50,
    role_id: 0,
    start_date: '',
    status: ''
})

export const policyACP = ref({
    agent: [1, 9].includes(role_id) ? 'all' : 'mine',
    end_date: "",
    index: 1,
    keyword: "",
    payment_type: "",
    per_page: 50,
    policy_sales_agent: "",
    start_date: "",
    status: 0,
    task_due_dates: "",
    type: '',
    underwriter: "",
    page: 1
})

export const marketingSpendP = ref({
    campaign: "",
    year_month: new Date().getFullYear() + '-' + String(new Date().getMonth() + 1).padStart(2, '0'),
    year: new Date().getFullYear(),
})

export const marketingReportP = ref({
    keyword: "",
    start_date: "",
    end_date: "",
    agent: "",
    export: false
})

export const invoiceP = ref({
    agent: [1, 9].includes(role_id) ? 'all' : 'mine',
    end_date: "",
    index: 1,
    page: 1,
    is_amend: -1,
    is_discrepancy: null,
    is_manual_order: null,
    keyword: "",
    payment_type: -1,
    per_page: "50",
    reload: true,
    start_date: "",
    status: 2,
    tabTask: 'all',
    route: ''
})



export const showLeads = ref(false)
export const ltCarLeadCounts = ref([])
export const ltCarLeadToolbarCounts = ref([])
export const dateRanges = ref([])
export const search = ref('')
export const userSearch = ref('')
export const transferedCustomers = ref([])
export const enableLeads = computed(() => {
    if(leadsTaskP.value.agent === -1 || [1, 9, 13, 14, 15].includes(role_id))  return true

    return false
})

function conditions(route_name) {
    switch(route_name) {
        case 'customer-list': return 'pLeadsAndTasks'
        case 'policy-assigned': return 'pAssignedPolicy'
        case 'policy-completed': return 'pCompletedPolicy'
        case 'policy-cancellation-request': return 'pCancellationRequest'
        case 'policy-cancelled': return 'pCancelled'
        case 'policy-refund-closed': return 'pRefundClosed'
        case 'policy-cancellation-approval': return 'pApproveViewCancellation'
        case 'policy-refund-approval': return 'pApproveViewRefund'
        case 'report-approved-invoice':
        case 'invoice-list': return 'pApprovedInvoice'
        case 'declined-invoice': return 'pDeclinedInvoice'
        case 'policy-approval': return 'pInvoiceForApproval'
        case 'marketing-campaigns': return 'pMarketingCampaigns'
        case 'marketing-spend':
        case 'marketing-spend-tracking': return 'pMarketingSpend'
        case 'marketing-report':
        case 'marketing-report-agent':
        case 'get-active-inactive-agents': return 'pMarketingReport'
        
        case 'report-policy': return 'pPolicyAssignedVsCompleted'
        case 'report-agent-sales': return 'pAgentSales'
        case 'report-account-master': return 'pAccountMaster'
        case 'report-discrepancy': return 'pDiscrepancy'
        case 'dashboard-details-report': return 'pDashboardDetailReport'
        case 'report-lead-by-source': return 'pLeadBySourceReport'
        case 'report-skye-login-info': return 'pSkyeLoginInfo'
        case 'report-closed-leads': return 'pClosedLeadsReport'
        case 'report-deleted-leads': return 'pDeletedLeadsReport'
        case 'al-manarah-report': return 'pProductionReport'
        case 'admin-report': return 'pAdminReport'
        case 'renewal-status': return 'adminManager'
        case 'lost-lead-report': return 'adminManager'
        case 'agent-escalations': return 'adminManager'
        case 'underwriter-escalations': return 'pAgentSales'
        case 'sales-tracking-report': return 'pSalesTrackingReport'
        case 'agent-lead-activity-report': return 'pAgentLeadActivityReport'
        default: return ''
    }
}

function setType(route_name) {
    switch(route_name) {
        case 'policy-assigned': return 'policy_assigned'
        case 'policy-completed': return 'policy_completed'
        case 'policy-cancellation-approval':
        case 'policy-cancellation-request': return 'cancellation_request'
        case 'policy-cancelled': return 'cancellation_completed'
        case 'policy-refund-closed': return 'refund_completed'
        case 'policy-refund-approval': return 'refund_approve'
        default: return ''
    }
}

function setStatus(route_name) {
    switch(route_name) {
        case 'policy-assigned': return 2
        case 'policy-completed': return 4
        case 'policy-cancellation-request': return 5
        case 'policy-cancellation-approval': return 6
        case 'policy-refund-closed': return 10
        case 'policy-cancelled': return [7, 11]
        default: return ''
    }
}