export const leadsTaksFourth = [
    {
        label: 'All <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'all'
    },
    {
        label: 'Today <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'today',
        isDisabled: true
    },
    {
        label: 'Yesterday <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'yesterday',
        isDisabled: true
    },
    {
        label: 'Last 30 days <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'last_30_days',
        isDisabled: true
    },
    {
        label: 'Older > 30 days <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'older_than_30_days',
        isDisabled: true
    },
    {
        label: 'Tomorrow <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'tomorrow',
        isDisabled: true
    }
    // {
    //     label: 'Upcoming <span class="text-italic fw-boldest">(COUNT)</span>',
    //     value: 'upcoming',
    //     isDisabled: true
    // }
]

export const PolicyCounts = [
    {
        label: 'All <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'all'
    },
    {
        label: 'Today <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'today',
        isDisabled: true
    },
    {
        label: 'Yesterday <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'yesterday',
        isDisabled: true
    },
    {
        label: 'Last 30 days <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'last_30_days',
        isDisabled: true
    },
    {
        label: 'Older > 30 days <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'older_than_30_days',
        isDisabled: true
    }
]

export const ApprovedCounts = [
    {
        label: 'All <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'all'
    },
    {
        label: 'New Invoices <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'new'
    },
    {
        label: 'Amend Invoices <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'amend_invoice'
    },
    {
        label: 'Manual Orders <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'manual_order'
    },
    {
        label: 'Discrepancies <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'discrepancy'
    }
]

export const ForApprovalCounts = [
    {
        label: 'COD / Direct / Intransact <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'cod_direct_count'
    },
    {
        label: 'Manual Orders <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'manual_order_count'
    },
    {
        label: 'Premium Discrepancy <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'discrepancy_count'
    },
    {
        label: 'Online <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'online_count'
    },
    {
        label: 'Unsuccessful Payments <span class="text-italic fw-boldest">(COUNT)</span>',
        value: 'failed_payments_count'
    }
]

export const per_pages = [50, 100, 150, 200, 500]

export const policyAssignedHeaderFilters = ['is_policy_completed', 'is_policy_refunded', 'is_policy_cancelled', 'is_policy_refunded_cancelled']
export const policyCompletedHeaderFilters = ['is_policy_assigned', 'is_policy_refunded', 'is_policy_cancelled', 'is_policy_refunded_cancelled']
export const policyCancelledHeaderFilters = ['is_policy_assigned', 'is_policy_refunded']
export const policyRefundClosedHeaderFilters = ['is_policy_assigned', 'is_policy_cancelled']

export const marketingReportHeadersDontShow = ['costOfLeads', 'costOfDeals']
export const marketingReportAgentHeadersDontShow = ['amountSpent', 'costPerLead']

export const userStatuses = [
    {
        value: '',
        label: 'Select User Status'
    },
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'Inactive'
    },
]